
.App {
  background-color: #b3009eff;
  padding: 0px;
  text-align: center;
  /* height: 100vh; */
}

.App-box {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); */
  background-color: #d788f1ff;
  padding-top: 50px;
}

.App-title {
  font-size: 4.5em;
  color: #ffffff;
  padding-top: 0px;
  margin: 10px;
  line-height: 1;
  font-family: 'Alegreya Sans SC', sans-serif;
}

.App-subtitle {
  font-size: 1.8em;
  color: #FFFFFF;
  margin: 10px;
  font-family: 'Alegreya Sans SC', sans-serif;
}

.App-subtitle2 {
  font-size: 1.5em;
  color: #ffffff;
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: 'Acme', sans-serif;
}

.App-table {
  font-size: 1.1em;
  color: #FFFFFF;
  margin: 10px;
  font-family: 'Acme', sans-serif;
  margin-left: auto;
  margin-right: auto;
}

.App-table td
{
    padding:0px 20px 10px 20px; /* Only right padding*/
    text-align: center;
}

.App-intro {
  font-size: large;
}

.App-logo {
  height: 150px;
  margin: 10px;
  margin-bottom: 0px;
  /* animation: shake infinite 2.0s cubic-bezier(.36,.07,.19,.97) both; */
}

.App-photo {
  height:300px;
  margin: 20px;
}

.App-appstore {
  height: 40px;
  margin: 20px;
}

.App-contact {
  font-size: 1.3em;
  color: #FFFFFF;
  margin: 10px;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 50px;
}

@media screen and (min-width: 576px) {
  .App-title {
    font-size: 5.5em;
  } 
  .App-subtitle {
    font-size: 2.0em;
  }
  .App-appstore {
    height: 40px;
    margin: 20px;
  }
  .App-logo {
    height: 220px;
  }
}

@media screen and (min-height: 900px) {
  .App-box {
    padding-top: 50px;
  } 
}

@keyframes shake {
  5%, 45% {
    transform: translate3d(0, -1px, 0);
  }
  
  10%, 40% {
    transform: translate3d(0, 1px, 0);
  }

  15%, 25%, 35% {
    transform: translate3d(0, -2px, 0);
  }

  20%, 30% {
    transform: translate3d(0, 2px, 0);
  }
}

/* unvisited link */
a:link {
  font-size: 1.8em;
  color: #FFC107;
  text-decoration: none;
  font-family: 'Alegreya Sans SC', sans-serif;
}

/* visited link */
a:visited {
  font-size: 1.8em;
  color: #FFC107;
  text-decoration: none;
  font-family: 'Alegreya Sans SC', sans-serif;
}

/* mouse over link */
a:hover {
  color: #FFFFFF;
  text-decoration: none;
}

/* selected link */
a:active {
  color:#000000;
  text-decoration: none;
}
